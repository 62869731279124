//

.banner {
  padding: 8% 0 4% 0;
  // padding: 1rem 0;
  background-image: linear-gradient(
    to left,
    $dark-primary-color,
    $primary-color
  );
  color: white;

  &--title {
    font-size: 3rem;
    font-weight: 500;
    padding: 10px 0;
  }
  &--subtitle {
    font-weight: 500;
    padding: 20px 0;
  }

  &--search {
    width: 50%;
  }
}
