//

.github {
  background-color: black !important;
  &:hover {
    background-color: rgb(77, 64, 64) !important;
  }
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;

  &--figure {
    width: 100%;
    padding: 14px 0;
    img {
      width: 150px;
      height: auto;
      border-radius: 50%;
    }
  }

  &--title {
    font-size: 1.6rem;
    font-weight: 500;
    padding-top: 12px;
  }
  &--text {
    font-size: 1rem;
    font-weight: 500;
    padding-top: 6px;
  }

  &--bio {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 0;

    &-text {
      font-size: 1rem;
      font-weight: 400;
      padding-bottom: 5px;
    }

    &-info {
      max-width: 70%;
      padding: 10px 0;
      text-align: left;
    }
  }

  &--buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  &--button {
    display: block;
    border-radius: 5px;
    font-size: 12px;
    padding: 12px;
    margin: 12px auto;
    width: 140px;
    background-color: $primary-color;
    color: white;
    text-decoration: none;
    @include opacity(0.9);
    &:hover {
      background-color: $dark-primary-color;
      @include transition(all 0.3s);
      cursor: pointer;
      @include opacity(1);
    }
  }
}

@media only screen and (min-width: 640px) {
  .user {
    flex-direction: row;

    &--bio {
      align-items: flex-start;

      &-text {
        font-size: 1.3rem;
        line-height: 1.4;
      }
    }

    &--figure {
      img {
        width: 200px;
      }
    }
  }
}
