//

%img-button {
  background-color: transparent;
  border: none transparent;
  outline: none;
  text-decoration: none;
  border-radius: 2px;
  line-height: normal;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  text-rendering: auto;
}

.followers {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
  background-color: white;

  &--header {
    margin-top: 10px;
  }

  &--list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0;
  }

  &--item {
    padding: 20px 15px 0 15px;

    &-button {
      @extend %img-button;
    }
  }

  img {
    width: 100px;
    height: auto;
    border-radius: 50%;
    &:hover {
      background-color: $dark-primary-color;
      @include transition(all 0.3s);
      cursor: pointer;
      @include opacity(1);
    }
  }

  &--buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 30px 0;
  }

  &--button {
    display: block;
    border-radius: 5px;
    font-size: 20px;
    padding: 9px;
    width: 50px;
    background-color: $primary-color;
    color: white;
    text-decoration: none;
    @include opacity(0.9);

    &:disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.4;

      &:hover {
        opacity: 0.4; //this is what you want
      }
    }

    &:hover {
      background-color: $dark-primary-color;
      @include transition(all 0.3s);
      cursor: pointer;
      @include opacity(1);
    }
  }

  &--text {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 640px) {
  .followers {
    &--item {
      padding: 25px 20px;
    }

    img {
      width: 100px;
    }
  }
}
