//

%button {
  background-color: transparent;
  border: none transparent;
  outline: none;
  text-decoration: none;
  border-radius: 2px;
  line-height: normal;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  text-rendering: auto;
}

$header-height: 64px;

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $header-color;
  color: $dark-primary-color;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: $header-height;
  width: 100%;

  padding-left: 24px;
  padding-right: 24px;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  &--spacer {
    height: $header-height;
    background-color: transparent;
  }

  &--icon {
    width: 40px;
    height: 40px;
    margin: 0 0.5em;
  }

  &--title-link {
    color: $dark-primary-color;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    text-decoration: none;

    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.0075em;
  }

  &--nav-link {
    @extend %button;

    color: white;
    background-color: $primary-color;

    padding: 8px 16px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.029em;

    min-width: 64px;
    min-height: 36px;

    transition: color 0.1s ease, border-color 0.1s ease;

    &:hover {
      background-color: $dark-primary-color;
    }
  }
}

@media only screen and (max-width: 350px) {
  .header {
    padding-left: 0;
    padding-right: 0;
  }
}
